<template>
    <v-card color="blue-grey darken-2" class="white--text">
        <v-btn v-if="!full_message" small absolute dark fab top right color="red" @click="delete_message(message.id)" style="z-index: 1;">
              <v-icon>mdi-close</v-icon>
            </v-btn>
      <v-card-title primary-title>
        <div>
            <router-link class="headline" v-if="!full_message" :to="'/message/' + message.id">{{ message.subject }}</router-link><br/>
            <div class="headline" v-if="full_message">{{ message.subject }}</div>
            {{ message.send_time | unixdate }}
        </div>
      </v-card-title>
      <v-card-text v-if="full_message">
        <v-container
            fluid
            grid-list-md
        >
            <v-layout row wrap justify-space-between>
                <v-flex v-for="(photo, index) in photos" :key="photo.id" v-on:click="navigate(photo, index)" shrink>
                    <Photo :photo="photo" ></Photo>
                </v-flex>
            </v-layout>
        </v-container>
      </v-card-text>
      <v-carousel v-if="!full_message" cycle continuous height="375" interval="5000" hide-delimiters>
          <v-carousel-item
              v-for="(photo, index) in photos"
              :key="photo.id"
              reverse-transition="fade-transition"
              transition="fade-transition"
          >
              <v-container fluid fill-height>
                  <v-col>
                      <v-row align="center" justify="center" shrink>
                        <Photo :photo="photo" ></Photo>
                      </v-row>
                  </v-col>
              </v-container>
          </v-carousel-item>
      </v-carousel>
      <v-card-actions>
      </v-card-actions>
    </v-card>
</template>

<script>
    import Photo from "../Photo";
    import {API} from 'aws-amplify'
    export default {
        name: "FamilyMemberImages",
        components: {Photo},
        props: ['message', 'full_message'],
        data: function(){
            return {
                photos: null,
                expanded: false
            }
        },
        mounted: function(){
            API.post('gtf', '/photos/check', {
               body: this.message.message_data.images,
               headers: {'Content-Type': 'application/json'}
            }).then(result => {
                console.info("Data from Photos check");
                for (let [key, val] of Object.entries(result)) {
                    //Commit Photo to Store
                    this.$store.commit('addImage', val)
                }
                this.photos = result;
            });
        },
        methods:{
            navigate(photo, index){
                this.$router.push({path: '/photo/' + photo.id,
                                   query: {person: this.person_id,
                                           location: this.location_id,
                                           index: index
                }})
            },
            delete_message(message_id){
                API.del('gtf', '/message/' + message_id, {
                   body: {},
                   headers: {'Content-Type': 'application/json'}
                }).then(result => {
                    this.$emit('delete_message', message_id)
                });
            }
        },
        filters: {
            unixdate: function (value) {
                let dt = new Date(value);
                return dt.toLocaleString();
            },
        }
    }
</script>

<style scoped>

</style>
